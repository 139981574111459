import * as React from 'react';
import { Typography, Box, useTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ImageListItem from "@mui/material/ImageListItem";
import { theme3, theme2 } from '../Theme.js';
import { CircleLoader } from "react-spinners";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {

  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  useTheme();

  return (

    <React.Fragment>

    <ThemeProvider theme={theme3}>
    <CssBaseline />
      <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

        <Typography display="block" align="center" fontSize={50} >
          Getting Ready - Kiara
        </Typography>
        <Typography> &nbsp; </Typography>
          <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          paddingBottom: '100px',
          [theme3.breakpoints.up('mobile')]: {
            width: 100,
            gridTemplateColumns:"repeat(1, 1fr)"
          },
          [theme3.breakpoints.up('bigMobile')]: {
            width: 300,
            gridTemplateColumns:"repeat(2, 1fr)"
          },
          [theme3.breakpoints.up('table')]: {
            width: 600,
            gridTemplateColumns:"repeat(4, 1fr)"
          },
          [theme3.breakpoints.up('desktop')]: {
            width: 900,
            gridTemplateColumns:"repeat(6, 1fr)"
          }
        }}
      >
        {itemData.map((item, index) => (
          <ImageListItem key={item.img}
           cols={item.cols || 1}
           rows={item.rows || 1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'pointer',
              '&:hover': {opacity:1}
           }}
           >
          {loading && (
      <div className="spinner-container">
        <CircleLoader color="#CB8A36" cssOverride={{ position: 'absolute', right: 100 }} loading size={100} />
      </div>
    )}
    <img
      {...srcset(get_image_url(item.img), 200, item.rows, item.cols)}
      alt={item.title}
      loading="lazy"
      preload="auto"
      onLoad={handleImageLoad} // Call the handleImageLoad function on image load
      style={{ opacity: loading ? 0 : 1 }} // Show image once loaded
    />
          </ImageListItem>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
    <ThemeProvider theme={theme2}>
      <CssBaseline/>
      <div>
        <p style={{ fontSize: '12px' }}>
        Fotografías por{' '}
          <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
          <a href="https://www.instagram.com/twoisbetterpr/" style={{ textDecoration: 'none' }} target="_blank">twoisbetterpr</a>.
          <br/>
          &copy; Derechos de autor twoisbetterpr 2023 - Todos los derechos reservados.
        </p>
      </div>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
    </ThemeProvider>
    </React.Fragment>

  );
}

const get_image_url = (imageId) => {
  return `https://gkwedding.blob.core.windows.net/images/GettingReadyKiara/${imageId}.jpg`;
}

const itemData = [
  {
    img: '0H7A5332',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5350',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5361',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5370',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5396',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5406',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5447',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5452',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5459',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5468',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5473',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5475',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5478',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5482',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5485',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5489',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5504',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5512',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5523',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5540',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5556',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5581',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5599',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5644',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5681',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5726',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5760',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5799',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5825',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5849',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5857',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5908',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5910',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5927',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5963',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1687',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1742',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1758',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1777',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1864',
    rows: 2,
    cols: 2,
  }
];
