import * as React from 'react';
import { Typography, Box, useTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ImageListItem from "@mui/material/ImageListItem";
import { theme3, theme2 } from '../Theme.js';
import { CircleLoader } from "react-spinners";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {

  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  useTheme();

  return (

    <React.Fragment>

    <ThemeProvider theme={theme3}>
    <CssBaseline />
      <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

        <Typography display="block" align="center" fontSize={50} >
          Boda - Ceremonia
        </Typography>
        <Typography> &nbsp; </Typography>
          <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          paddingBottom: '100px',
          [theme3.breakpoints.up('mobile')]: {
            width: 100,
            gridTemplateColumns:"repeat(1, 1fr)"
          },
          [theme3.breakpoints.up('bigMobile')]: {
            width: 300,
            gridTemplateColumns:"repeat(2, 1fr)"
          },
          [theme3.breakpoints.up('table')]: {
            width: 600,
            gridTemplateColumns:"repeat(4, 1fr)"
          },
          [theme3.breakpoints.up('desktop')]: {
            width: 900,
            gridTemplateColumns:"repeat(6, 1fr)"
          }
        }}
      >
        {itemData.map((item, index) => (
          <ImageListItem key={item.img}
           cols={item.cols || 1}
           rows={item.rows || 1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'pointer',
              '&:hover': {opacity:1}
           }}
           >
          {loading && (
      <div className="spinner-container">
        <CircleLoader color="#CB8A36" cssOverride={{ position: 'absolute', right: 100 }} loading size={100} />
      </div>
    )}
    <img
      {...srcset(get_image_url(item.img), 200, item.rows, item.cols)}
      alt={item.title}
      loading="lazy"
      preload="auto"
      onLoad={handleImageLoad} // Call the handleImageLoad function on image load
      style={{ opacity: loading ? 0 : 1 }} // Show image once loaded
    />
          </ImageListItem>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
    <ThemeProvider theme={theme2}>
      <CssBaseline/>
      <div>
        <p style={{ fontSize: '12px' }}>
        Fotografías por{' '}
          <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
          <a href="https://www.instagram.com/twoisbetterpr/" style={{ textDecoration: 'none' }} target="_blank">twoisbetterpr</a>.
          <br/>
          &copy; Derechos de autor twoisbetterpr 2023 - Todos los derechos reservados.
        </p>
      </div>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
    </ThemeProvider>
    </React.Fragment>

  );
}

const get_image_url = (imageId) => {
  return `https://gkwedding.blob.core.windows.net/images//container/${imageId}.jpg`;
}

const itemData = [
  { img: '0H7A5322', rows: 2, cols: 2 },
  { img: '0H7A6281', rows: 2, cols: 2 },
  { img: '0H7A6301', rows: 2, cols: 2 },
  { img: '0H7A6325', rows: 2, cols: 2 },
  { img: '0H7A6363', rows: 2, cols: 2 },
  { img: '0H7A6377', rows: 2, cols: 2 },
  { img: '0H7A6382', rows: 2, cols: 2 },
  { img: '0H7A6384', rows: 2, cols: 2 },
  { img: '0H7A6386', rows: 2, cols: 2 },
  { img: '0H7A6392', rows: 2, cols: 2 },
  { img: '0H7A6401', rows: 2, cols: 2 },
  { img: '0H7A6403', rows: 2, cols: 2 },
  { img: '0H7A6404', rows: 2, cols: 2 },
  { img: '0H7A6406', rows: 2, cols: 2 },
  { img: '0H7A6480', rows: 2, cols: 2 },
  { img: '0H7A6507', rows: 2, cols: 2 },
  { img: '0H7A6508', rows: 2, cols: 2 },
  { img: '0H7A6519', rows: 2, cols: 2 },
  { img: '0H7A6536', rows: 2, cols: 2 },
  { img: '0H7A6539', rows: 2, cols: 2 },
  { img: '0H7A6542', rows: 2, cols: 2 },
  { img: '0H7A6552', rows: 2, cols: 2 },
  { img: '0H7A6567', rows: 2, cols: 2 },
  { img: '0H7A6600', rows: 2, cols: 2 },
  { img: '0H7A6616', rows: 2, cols: 2 },
  { img: '0H7A6618', rows: 2, cols: 2 },
  { img: '0H7A6648', rows: 2, cols: 2 },
  { img: '0H7A6658', rows: 2, cols: 2 },
  { img: '0H7A6667', rows: 2, cols: 2 },
  { img: '0H7A6679', rows: 2, cols: 2 },
  { img: '0H7A6681', rows: 2, cols: 2 },
  { img: '0H7A6698', rows: 2, cols: 2 },
  { img: '0H7A6706', rows: 2, cols: 2 },
  { img: '0H7A6710', rows: 2, cols: 2 },
  { img: '0H7A6711', rows: 2, cols: 2 },
  { img: '0H7A6717', rows: 2, cols: 2 },
  { img: '0H7A6726', rows: 2, cols: 2 },
  { img: '0H7A6727', rows: 2, cols: 2 },
  { img: '0H7A6731', rows: 2, cols: 2 },
  { img: '0H7A6740', rows: 2, cols: 2 },
  { img: '0H7A6743', rows: 2, cols: 2 },
  { img: '0H7A6745', rows: 2, cols: 2 },
  { img: '0H7A6747', rows: 2, cols: 2 },
  { img: '0H7A6749', rows: 2, cols: 2 },
  { img: '0H7A6755', rows: 2, cols: 2 },
  { img: '0H7A6760', rows: 2, cols: 2 },
  { img: '6D7A2037', rows: 2, cols: 2 },
  { img: '6D7A2040', rows: 2, cols: 2 },
  { img: '6D7A2046', rows: 2, cols: 2 },
  { img: '6D7A2081', rows: 2, cols: 2 },
  { img: '6D7A2084', rows: 2, cols: 2 },
  { img: '6D7A2091', rows: 2, cols: 2 },
  { img: '6D7A2099', rows: 2, cols: 2 },
  { img: '6D7A2119', rows: 2, cols: 2 },
  { img: '6D7A2131', rows: 2, cols: 2 },
  { img: '6D7A2243', rows: 2, cols: 2 },
  { img: '6D7A2289', rows: 2, cols: 2 },
  { img: '6D7A2295', rows: 2, cols: 2 }
];
