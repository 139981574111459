import * as React from 'react';
import { Typography, Box, useTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ImageListItem from "@mui/material/ImageListItem";
import { theme3, theme2 } from '../Theme.js';
import { CircleLoader } from "react-spinners";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {

  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  useTheme();

  return (

    <React.Fragment>

    <ThemeProvider theme={theme3}>
    <CssBaseline />
      <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

        <Typography display="block" align="center" fontSize={50} >
          Boda - Recepción
        </Typography>
        <Typography> &nbsp; </Typography>
          <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          paddingBottom: '100px',
          [theme3.breakpoints.up('mobile')]: {
            width: 100,
            gridTemplateColumns:"repeat(1, 1fr)"
          },
          [theme3.breakpoints.up('bigMobile')]: {
            width: 300,
            gridTemplateColumns:"repeat(2, 1fr)"
          },
          [theme3.breakpoints.up('table')]: {
            width: 600,
            gridTemplateColumns:"repeat(4, 1fr)"
          },
          [theme3.breakpoints.up('desktop')]: {
            width: 900,
            gridTemplateColumns:"repeat(6, 1fr)"
          }
        }}
      >
        {itemData.map((item, index) => (
          <ImageListItem key={item.img}
           cols={item.cols || 1}
           rows={item.rows || 1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'pointer',
              '&:hover': {opacity:1}
           }}
           >
          {loading && (
      <div className="spinner-container">
        <CircleLoader color="#CB8A36" cssOverride={{ position: 'absolute', right: 100 }} loading size={100} />
      </div>
    )}
    <img
      {...srcset(get_image_url(item.img), 200, item.rows, item.cols)}
      alt={item.title}
      loading="lazy"
      preload="auto"
      onLoad={handleImageLoad} // Call the handleImageLoad function on image load
      style={{ opacity: loading ? 0 : 1 }} // Show image once loaded
    />
          </ImageListItem>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
    <ThemeProvider theme={theme2}>
      <CssBaseline/>
      <div>
        <p style={{ fontSize: '12px' }}>
        Fotografías por{' '}
          <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
          <a href="https://www.instagram.com/twoisbetterpr/" style={{ textDecoration: 'none' }} target="_blank">twoisbetterpr</a>.
          <br/>
          &copy; Derechos de autor twoisbetterpr 2023 - Todos los derechos reservados.
        </p>
      </div>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
    </ThemeProvider>
    </React.Fragment>

  );
}

const get_image_url = (imageId) => {
  return `https://gkwedding.blob.core.windows.net/images/recepcion/${imageId}.jpg`;
}

const itemData = [
  { img: '0H7A7042', rows: 2, cols: 2 },
  { img: '0H7A7048', rows: 2, cols: 2 },
  { img: '0H7A7165', rows: 2, cols: 2 },
  { img: '0H7A7181', rows: 2, cols: 2 },
  { img: '0H7A7195', rows: 2, cols: 2 },
  { img: '0H7A7210', rows: 2, cols: 2 },
  { img: '0H7A7220', rows: 2, cols: 2 },
  { img: '0H7A7222', rows: 2, cols: 2 },
  { img: '0H7A7233', rows: 2, cols: 2 },
  { img: '0H7A7235', rows: 2, cols: 2 },
  { img: '0H7A7238', rows: 2, cols: 2 },
  { img: '0H7A7240', rows: 2, cols: 2 },
  { img: '0H7A7254', rows: 2, cols: 2 },
  { img: '0H7A7265', rows: 2, cols: 2 },
  { img: '0H7A7268', rows: 2, cols: 2 },
  { img: '0H7A7297', rows: 2, cols: 2 },
  { img: '0H7A7327', rows: 2, cols: 2 },
  { img: '0H7A7332', rows: 2, cols: 2 },
  { img: '0H7A7338', rows: 2, cols: 2 },
  { img: '0H7A7357', rows: 2, cols: 2 },
  { img: '0H7A7360', rows: 2, cols: 2 },
  { img: '0H7A7372', rows: 2, cols: 2 },
  { img: '0H7A7393', rows: 2, cols: 2 },
  { img: '0H7A7395', rows: 2, cols: 2 },
  { img: '0H7A7398', rows: 2, cols: 2 },
  { img: '0H7A7401', rows: 2, cols: 2 },
  { img: '0H7A7404', rows: 2, cols: 2 },
  { img: '0H7A7423', rows: 2, cols: 2 },
  { img: '0H7A7427', rows: 2, cols: 2 },
  { img: '0H7A7428', rows: 2, cols: 2 },
  { img: '0H7A7440', rows: 2, cols: 2 },
  { img: '0H7A7442', rows: 2, cols: 2 },
  { img: '0H7A7447', rows: 2, cols: 2 },
  { img: '0H7A7456', rows: 2, cols: 2 },
  { img: '0H7A7489', rows: 2, cols: 2 },
  { img: '0H7A7500', rows: 2, cols: 2 },
  { img: '0H7A7502', rows: 2, cols: 2 },
  { img: '0H7A7523', rows: 2, cols: 2 },
  { img: '0H7A7532', rows: 2, cols: 2 },
  { img: '0H7A7535', rows: 2, cols: 2 },
  { img: '0H7A7536', rows: 2, cols: 2 },
  { img: '0H7A7538', rows: 2, cols: 2 },
  { img: '0H7A7549', rows: 2, cols: 2 },
  { img: '0H7A7556', rows: 2, cols: 2 },
  { img: '0H7A7561', rows: 2, cols: 2 },
  { img: '0H7A7562', rows: 2, cols: 2 },
  { img: '0H7A7571', rows: 2, cols: 2 },
  { img: '0H7A7580', rows: 2, cols: 2 },
  { img: '0H7A7592', rows: 2, cols: 2 },
  { img: '0H7A7595', rows: 2, cols: 2 },
  { img: '0H7A7599', rows: 2, cols: 2 },
  { img: '0H7A7602', rows: 2, cols: 2 },
  { img: '0H7A7609', rows: 2, cols: 2 },
  { img: '0H7A7613', rows: 2, cols: 2 },
  { img: '0H7A7619', rows: 2, cols: 2 },
  { img: '0H7A7635', rows: 2, cols: 2 },
  { img: '0H7A7644', rows: 2, cols: 2 },
  { img: '0H7A7651', rows: 2, cols: 2 },
  { img: '0H7A7653', rows: 2, cols: 2 },
  { img: '0H7A7655', rows: 2, cols: 2 },
  { img: '0H7A7656', rows: 2, cols: 2 },
  { img: '0H7A7657', rows: 2, cols: 2 },
  { img: '0H7A7659', rows: 2, cols: 2 },
  { img: '0H7A7663', rows: 2, cols: 2 },
  { img: '0H7A7670', rows: 2, cols: 2 },
  { img: '0H7A7671', rows: 2, cols: 2 },
  { img: '0H7A7677', rows: 2, cols: 2 },
  { img: '0H7A7680', rows: 2, cols: 2 },
  { img: '0H7A7696', rows: 2, cols: 2 },
  { img: '0H7A7702', rows: 2, cols: 2 },
  { img: '0H7A7703', rows: 2, cols: 2 },
  { img: '0H7A7707', rows: 2, cols: 2 },
  { img: '0H7A7708', rows: 2, cols: 2 },
  { img: '0H7A7722', rows: 2, cols: 2 },
  { img: '0H7A7724', rows: 2, cols: 2 },
  { img: '0H7A7738', rows: 2, cols: 2 },
  { img: '0H7A7739', rows: 2, cols: 2 },
  { img: '0H7A7744', rows: 2, cols: 2 },
  { img: '0H7A7747', rows: 2, cols: 2 },
  { img: '0H7A7749', rows: 2, cols: 2 },
  { img: '0H7A7752', rows: 2, cols: 2 },
  { img: '0H7A7754', rows: 2, cols: 2 },
  { img: '0H7A7765', rows: 2, cols: 2 },
  { img: '0H7A7773', rows: 2, cols: 2 },
  { img: '0H7A7775', rows: 2, cols: 2 },
  { img: '0H7A7783', rows: 2, cols: 2 },
  { img: '0H7A7784', rows: 2, cols: 2 },
  { img: '0H7A7789', rows: 2, cols: 2 },
  { img: '0H7A7791', rows: 2, cols: 2 },
  { img: '0H7A7793', rows: 2, cols: 2 },
  { img: '0H7A7796', rows: 2, cols: 2 },
  { img: '0H7A7798', rows: 2, cols: 2 },
  { img: '0H7A7802', rows: 2, cols: 2 },
  { img: '0H7A7807', rows: 2, cols: 2 },
  { img: '0H7A7809', rows: 2, cols: 2 },
  { img: '0H7A7811', rows: 2, cols: 2 },
  { img: '0H7A7816', rows: 2, cols: 2 },
  { img: '0H7A7866', rows: 2, cols: 2 },
  { img: '0H7A7877', rows: 2, cols: 2 },
  { img: '0H7A7880', rows: 2, cols: 2 },
  { img: '0H7A7889', rows: 2, cols: 2 },
  { img: '0H7A7893', rows: 2, cols: 2 },
  { img: '0H7A7895', rows: 2, cols: 2 },
  { img: '0H7A7904', rows: 2, cols: 2 },
  { img: '0H7A7909', rows: 2, cols: 2 },
  { img: '0H7A7915', rows: 2, cols: 2 },
  { img: '0H7A7918', rows: 2, cols: 2 },
  { img: '0H7A7922', rows: 2, cols: 2 },
  { img: '0H7A7941', rows: 2, cols: 2 },
  { img: '0H7A7943', rows: 2, cols: 2 },
  { img: '0H7A7948', rows: 2, cols: 2 },
  { img: '0H7A7952', rows: 2, cols: 2 },
  { img: '0H7A7958', rows: 2, cols: 2 },
  { img: '0H7A7960', rows: 2, cols: 2 },
  { img: '0H7A7961', rows: 2, cols: 2 },
  { img: '0H7A7963', rows: 2, cols: 2 },
  { img: '0H7A7974', rows: 2, cols: 2 },
  { img: '0H7A7976', rows: 2, cols: 2 },
  { img: '0H7A7981', rows: 2, cols: 2 },
  { img: '0H7A7983', rows: 2, cols: 2 },
  { img: '0H7A7987', rows: 2, cols: 2 },
  { img: '0H7A7994', rows: 2, cols: 2 },
  { img: '0H7A8007', rows: 2, cols: 2 },
  { img: '0H7A8010', rows: 2, cols: 2 },
  { img: '0H7A8013', rows: 2, cols: 2 },
  { img: '0H7A8021', rows: 2, cols: 2 },
  { img: '0H7A8026', rows: 2, cols: 2 },
  { img: '0H7A8029', rows: 2, cols: 2 },
  { img: '0H7A8034', rows: 2, cols: 2 },
  { img: '0H7A8043', rows: 2, cols: 2 },
  { img: '0H7A8047', rows: 2, cols: 2 },
  { img: '0H7A8050', rows: 2, cols: 2 },
  { img: '0H7A8054', rows: 2, cols: 2 },
  { img: '0H7A8067', rows: 2, cols: 2 },
  { img: '0H7A8073', rows: 2, cols: 2 },
  { img: '0H7A8076', rows: 2, cols: 2 },
  { img: '6D7A1987', rows: 2, cols: 2 },
  { img: '6D7A1992', rows: 2, cols: 2 },
  { img: '6D7A2003', rows: 2, cols: 2 },
  { img: '6D7A2008', rows: 2, cols: 2 },
  { img: '6D7A2010', rows: 2, cols: 2 },
  { img: '6D7A2015', rows: 2, cols: 2 },
  { img: '6D7A2548', rows: 2, cols: 2 },
  { img: '6D7A2553', rows: 2, cols: 2 },
  { img: '6D7A2559', rows: 2, cols: 2 },
  { img: '6D7A2570', rows: 2, cols: 2 },
  { img: '6D7A2619', rows: 2, cols: 2 },
  { img: '6D7A2635', rows: 2, cols: 2 },
  { img: '6D7A2653', rows: 2, cols: 2 },
  { img: '6D7A2664', rows: 2, cols: 2 },
  { img: '6D7A2688', rows: 2, cols: 2 },
  { img: '6D7A2704', rows: 2, cols: 2 },
  { img: '6D7A2741', rows: 2, cols: 2 },
  { img: '6D7A2745', rows: 2, cols: 2 },
  { img: '6D7A2770', rows: 2, cols: 2 },
  { img: '6D7A2771', rows: 2, cols: 2 },
  { img: '6D7A2792', rows: 2, cols: 2 },
  { img: '6D7A2816', rows: 2, cols: 2 },
  { img: '6D7A2820', rows: 2, cols: 2 },
  { img: '6D7A2834', rows: 2, cols: 2 },
  { img: '6D7A2839', rows: 2, cols: 2 },
  { img: '6D7A2840', rows: 2, cols: 2 },
  { img: '6D7A2842', rows: 2, cols: 2 },
  { img: '6D7A2924', rows: 2, cols: 2 },
  { img: '6D7A2964', rows: 2, cols: 2 },
  { img: '6D7A2972', rows: 2, cols: 2 },
  { img: '6D7A2978', rows: 2, cols: 2 },
  { img: '6D7A2979', rows: 2, cols: 2 },
  { img: '6D7A2989', rows: 2, cols: 2 },
  { img: '6D7A2998', rows: 2, cols: 2 },
  { img: '6D7A3022', rows: 2, cols: 2 },
  { img: '6D7A3029', rows: 2, cols: 2 },
  { img: '6D7A3032', rows: 2, cols: 2 },
  { img: '6D7A3032', rows: 2, cols: 2 }
];
