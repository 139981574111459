import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EventIcon from '@mui/icons-material/Event';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import QuizIcon from '@mui/icons-material/Quiz';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CssBaseline, Menu, MenuItem } from '@mui/material';  // Add Menu and MenuItem here
import { ThemeProvider } from '@mui/material/styles';
import { theme4, theme5 } from './Theme.js';
import './Navbar.css';

const pages = ['Historia', 'Fotos', 'Eventos', 'Viajeros', 'FAQ'];
const subMenuItems = [
  { path: 'savethedatefotos', label: 'Save the Date' },
  { path: 'rainierfotos', label: 'Rainier Photos' },
  { path: 'gettingreadygeraldo', label: 'Getting Ready Geraldo' },
  { path: 'gettingreadykiara', label: 'Getting Ready Kiara' },
  { path: 'couplesessionfotos', label: 'Couple Session Photos' },
  { path: 'ceremoniafotos', label: 'Ceremony Photos' },
  { path: 'recepcionfotos', label: 'Reception Photos' },
];

const ResponsiveAppBar = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);
  const toggleSubMenu = () => setOpenSubMenu(!openSubMenu);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const mobilePages = isMobile ? ['Home', ...pages] : pages;

  const getPageIcon = (page) => {
    switch (page) {
      case 'Home':
        return <HomeIcon />;
      case 'Fotos':
        return <PhotoLibraryIcon />;
      case 'Eventos':
        return <EventIcon />;
      case 'Historia':
        return <LocalLibraryIcon />;
      case 'Viajeros':
        return <ConnectingAirportsIcon />;
      case 'FAQ':
        return <QuizIcon />;
      default:
        return null;
    }
  };

  return (
    <AppBar elevation={0} position={isMobile ? "fixed" : "static"} style={{ background: 'transparent' }}>
      <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'center' }}>
        <Toolbar disableGutters style={{ alignItems: 'center' }}>
          <CssBaseline />
          <a href="/" style={{ textDecoration: "none", color: "inherit", position: 'relative', top: '1px' }} className="icon">
            <AllInclusiveIcon
              sx={{
                display: { xs: 'none', md: 'flex' },
                mr: 2,
                color: "black",
                fontSize: 26,
                marginRight: 1,
                marginTop: { xs: 0, md: '7px' },
              }}
            />
          </a>
          <ThemeProvider theme={theme5}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                fontSize: 16,
                mr: 2,
                display: { xs: 'none', md: 'none', sm: 'none', lg: 'flex', xl: 'flex' },
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >
              GKWEDDING
            </Typography>
          </ThemeProvider>

          {isMobile ? (
            <ThemeProvider theme={theme4}>
              <CssBaseline />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="open drawer"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenDrawer}
                  style={{ color: "black" }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}>
                  <List>
                    {mobilePages.map((page) => (
                      page === 'Fotos' ? (
                        <React.Fragment key={page}>
                          <ListItem button onClick={toggleSubMenu}>
                            <ListItemIcon sx={{ color: 'black' }}>
                              {getPageIcon(page)}
                            </ListItemIcon>
                            <ListItemText primary={page} />
                            {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {subMenuItems.map(({ path, label }) => (
                                <ListItem key={path} button component={Link} to={`/${path}`} onClick={() => {
                                  handleCloseDrawer();
                                  toggleSubMenu();
                                }} >
                                  <ListItemText primary={label} sx={{ pl: 4 }} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      ) : (
                        <ListItem key={page} button component={Link} to={`/${page}`} onClick={handleCloseDrawer}>
                          <ListItemIcon sx={{ color: 'black' }}>
                            {getPageIcon(page)}
                          </ListItemIcon>
                          <ListItemText primary={page} />
                        </ListItem>
                      )
                    ))}
                  </List>
                </Drawer>
              </Box>
            </ThemeProvider>
          ) : (
            <Box
              sx={{
                px: 0,
                margin: 0,
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
            >
              <ThemeProvider theme={theme4}>
                {pages.map((page) => (
                  page === 'Fotos' ? (
                    <Box key={page} sx={{ position: 'relative', margin: 3 }}>
                      <Button
                        onClick={handleMenuOpen}
                        sx={{
                          my: 2,
                          color: 'black',
                          textDecoration: 'none', 
                          borderRadius: '4px',
                          ':hover': {
                            textDecoration: 'underline',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      >
                        {page}
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                      >
                        <MenuItem component={Link} to="/savethedatefotos" onClick={handleMenuClose}>Save The Date Fotos</MenuItem>
                        <MenuItem component={Link} to="/rainierfotos" onClick={handleMenuClose}>Mount Rainier Fotos</MenuItem>
                        <MenuItem component={Link} to="/gettingreadygeraldo" onClick={handleMenuClose}>Boda Getting Ready Geraldo</MenuItem>
                        <MenuItem component={Link} to="/gettingreadykiara" onClick={handleMenuClose}>Boda Getting Ready Kiara</MenuItem>
                        <MenuItem component={Link} to="/couplesessionfotos" onClick={handleMenuClose}>Boda Couple Session </MenuItem>
                        <MenuItem component={Link} to="/ceremoniafotos" onClick={handleMenuClose}>Boda Ceremonia</MenuItem>
                        <MenuItem component={Link} to="/recepcionfotos" onClick={handleMenuClose}>Boda Recepción</MenuItem>
                      </Menu>
                    </Box>
                  ) : (
                    <Button
                      key={page}
                      component={Link}
                      to={`/${page}`}
                      sx={{
                        my: 2,
                        color: 'black',
                        margin: 4,
                        textDecoration: 'none',
                        borderRadius: '4px',
                        ':hover': {
                          textDecoration: 'underline',
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                      {page}
                    </Button>
                  )
                ))}
              </ThemeProvider>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
