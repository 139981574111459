import * as React from 'react';
import { Typography, Box, useTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ImageListItem from "@mui/material/ImageListItem";
import { theme3, theme2 } from '../Theme.js';
import { CircleLoader } from "react-spinners";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {

  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  useTheme();

  return (

    <React.Fragment>

    <ThemeProvider theme={theme3}>
    <CssBaseline />
      <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

        <Typography display="block" align="center" fontSize={50} >
          Getting Ready - Geraldo
        </Typography>
        <Typography> &nbsp; </Typography>
          <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          paddingBottom: '100px',
          [theme3.breakpoints.up('mobile')]: {
            width: 100,
            gridTemplateColumns:"repeat(1, 1fr)"
          },
          [theme3.breakpoints.up('bigMobile')]: {
            width: 300,
            gridTemplateColumns:"repeat(2, 1fr)"
          },
          [theme3.breakpoints.up('table')]: {
            width: 600,
            gridTemplateColumns:"repeat(4, 1fr)"
          },
          [theme3.breakpoints.up('desktop')]: {
            width: 900,
            gridTemplateColumns:"repeat(6, 1fr)"
          }
        }}
      >
        {itemData.map((item, index) => (
          <ImageListItem key={item.img}
           cols={item.cols || 1}
           rows={item.rows || 1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'pointer',
              '&:hover': {opacity:1}
           }}
           >
          {loading && (
      <div className="spinner-container">
        <CircleLoader color="#CB8A36" cssOverride={{ position: 'absolute', right: 100 }} loading size={100} />
      </div>
    )}
    <img
      {...srcset(get_image_url(item.img), 200, item.rows, item.cols)}
      alt={item.title}
      loading="lazy"
      preload="auto"
      onLoad={handleImageLoad} // Call the handleImageLoad function on image load
      style={{ opacity: loading ? 0 : 1 }} // Show image once loaded
    />
          </ImageListItem>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
    <ThemeProvider theme={theme2}>
      <CssBaseline/>
      <div>
        <p style={{ fontSize: '12px' }}>
        Fotografías por{' '}
          <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
          <a href="https://www.instagram.com/twoisbetterpr/" style={{ textDecoration: 'none' }} target="_blank">twoisbetterpr</a>.
          <br/>
          &copy; Derechos de autor twoisbetterpr 2023 - Todos los derechos reservados.
        </p>
      </div>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
    </ThemeProvider>
    </React.Fragment>

  );
}

const get_image_url = (imageId) => {
  return `https://gkwedding.blob.core.windows.net/images/GettingReadyGeraldo/${imageId}.jpg`;
}

const itemData = [
  {
    img: '0H7A5992',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A5997',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6000',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6015',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6044',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6050',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6065',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6116',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6143',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6160',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6165',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6198',
    rows: 2,
    cols: 2,
  },
  {
    img: '0H7A6217',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1908',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1920',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1931',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1943',
    rows: 2,
    cols: 2,
  },
  {
    img: '6D7A1985',
    rows: 2,
    cols: 2,
  }
];
